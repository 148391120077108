<button *ngIf="! started" (click)="startTone()">Start Tone fw</button>
<div *ngIf="started">
  <div>
    Transport: state={{transportState}}
    <button (click)="startTransport()">Start</button>
    <button (click)="stopTransport()">Stop</button>
  </div>

  <div>
    Metronome: muted={{metronomeMuted}} <button (click)="toggleMetronome()">Toggle</button>
  </div>

  <div>
    Recording: <button (click)="startRecording()">Start</button>
    <button (click)="stopRecording()">Stop</button>
  </div>

  <audio #audioplayer controls></audio>
</div>

<router-outlet></router-outlet>
