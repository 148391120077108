import {Component, ElementRef, ViewChild} from '@angular/core';
import {Synth, Transport, Player, Loop, UserMedia, Recorder} from "tone";
import * as Tone from "tone";
//import {Tone} from "tone/build/esm/core/Tone";

declare var MediaRecorder: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ws';
  private strongPlayer: Player;
  private weakPlayer: Player;
  private metroLoop: Loop;
  private beatNo = 0;

  private recorder: Recorder = undefined;

  private inputStreams = {};
  private inputDevices: MediaDeviceInfo[];
  public started = false;
  public audioUrl: string;

  @ViewChild('audioplayer') audioplayer: ElementRef;

  constructor() {
    this.strongPlayer = new Player('assets/metronome_strong.wav').toDestination();
    this.weakPlayer = new Player('assets/metronome_weak.wav').toDestination();

    this.metroLoop = new Loop((time) => {
      if (this.beatNo === 0) {
        this.strongPlayer.start(time);
      } else {
        this.weakPlayer.start(time);
      }
      this.beatNo += 1;
      if (this.beatNo === 4) {
        this.beatNo = 0;
      }
    }, '4n');
    this.metroLoop.mute = true;
    this.metroLoop.start();
  }

  startTransport() {
    Tone.Transport.start();
  }

  stopTransport() {
    Tone.Transport.stop();
  }

  get transportState() {
    return Tone.Transport.state;
  }

  get metronomeMuted() {
    return this.metroLoop.mute;
  }

  toggleMetronome() {
    this.metroLoop.mute = ! this.metroLoop.mute;
  }

  startRecording() {
    const userMedia: UserMedia = this.inputStreams['Default'];
    console.assert(this.recorder === undefined);
    this.recorder = new Recorder();
    userMedia.connect(this.recorder);
    this.recorder.start();
  }

  async stopRecording() {
    console.assert(this.recorder !== undefined);
    const data = await this.recorder.stop();
    console.log(data);
    this.recorder.dispose();
    this.recorder = undefined;

    console.log(this.audioplayer);
    this.audioplayer.nativeElement.src = URL.createObjectURL(data);

    /*
    const player = new Player(URL.createObjectURL(data)).toDestination();
    player.autostart = true;
    player.loop = true;
     */
  }

  async startTone() {
    await Tone.start();
    const userMedia = new UserMedia();
    this.inputDevices = await UserMedia.enumerateDevices();
    for (const inputDevice of this.inputDevices) {
      this.inputStreams[inputDevice.label] = await userMedia.open(inputDevice.label);
    }
    console.log(this.inputStreams);
    this.started = true;
  }
}
